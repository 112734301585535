@import '@/assets/css/index.scss';

.noStretch div:nth-last-child(1):nth-child(odd) {
    grid-column: span 0;
}

.col2Stretch :nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
}

.col3Stretch :nth-last-child(1):nth-child(even) {
    grid-column: span 3;
}

.col3Stretch :nth-child(3n):nth-last-child(1):nth-child(even) {
    grid-column: span 1;
}

.col3Stretch :nth-child(3n + 2):nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
}

.col3Stretch :nth-child(3n -1):nth-last-child(1):nth-child(even) {
    grid-column: span 2;
}

.col3Stretch :nth-child(3n -2):nth-last-child(1):nth-child(odd) {
    grid-column: span 3;
}

.col4Stretch :nth-child(4n -1):nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
}

.col4Stretch :nth-child(4n -2):nth-last-child(1):nth-child(even) {
    grid-column: span 3;
}

.col4Stretch :nth-child(4n -3):nth-last-child(1):nth-child(odd) {
    grid-column: span 4;
}
