@import '@/assets/css/index.scss';

@mixin loadingSpinner($duration: 700ms) {
    animation: spin $duration linear infinite;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadingSpinner {
    @include loadingSpinner(900ms);
}
