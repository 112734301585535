@import '@/assets/css/index.scss';

.progressBar {
    position: absolute;
    height: 4px;
    background-color: var(--color-lochmara);
    overflow: hidden;
    z-index: 1;

    @include desktop {
        height: 5px;
    }
}

.loopedLoadingBar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(
        45deg,
        var(--color-catalinaBlue),
        var(--color-azureRadiance),
        var(--color-dodgerBlue),
        var(--color-azureRadiance),
        var(--color-catalinaBlue)
    );
    background-size: 200% 200%;
    animation: animateGradient 1.5s infinite;
}

@keyframes animateGradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
